<template>
  <v-app>
    <v-content>
      <v-img
        :src="require('@/assets/Fondo-anaranjado-escritorio.png')"
        max-height="275"
      >
        <v-layout
          row
          wrap
          :ma-5="$vuetify.breakpoint.mdAndUp"
          :ma-3="$vuetify.breakpoint.smAndDown"
          :pa-5="$vuetify.breakpoint.mdAndUp"
          :pa-3="$vuetify.breakpoint.smAndDown"
        >
          <v-flex
            xs12
          >
            <span
              class="white--text headline"
            >
              <v-icon
                color="white"
                style="background: #00b2c0; border-radius: 12px; height: 35px; width: 35px;"
              >
                fal fa-headset
              </v-icon>
              Ayuda y Soporte Mandomedio
            </span>
          </v-flex>
          <v-flex xs12>
            <v-layout
              align-start
              row
            >
              <v-flex xs12>
                <span
                  class="font-weight-black white--text display-2"
                >
                  ¿Cómo te podemos ayudar?
                </span>
              </v-flex>
              <!--v-flex xs6>
                <v-text-field
                  flat
                  color="grey--text"
                  label="Busca en Ayuda y Soporte"
                  solo
                  append-icon="fal fa-search"
                />
              </v-flex!-->
            </v-layout>
          </v-flex>
        </v-layout>
      </v-img>
      <div style="background: #ffffff">
        <router-view />
      </div>
      <div style="background: #ff6625">
        <Footer />
      </div>
    </v-content>
  </v-app>
</template>

<script>
import Footer from '@/views/Footer.vue';

export default {
  name: 'FAQBase',
  components: {
    Footer,
  },
};

</script>
